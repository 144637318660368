@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import './theme.css'   ;

@import url("primereact/resources/primereact.min.css");                                       

@import url('primeicons/primeicons.css');
                
    
:root {
--base-body-size: 13px;
--body-medium-semibold-size: 15px;
--font-size-xs: 11px;
--Heading: 18px;
--primary-color: #5e17eb;
--dark-color: #010101;
--secondary-color: #8F5FEE;
--button-color-main:#5e17eb ;
--tertiary-color:#99D3DF ;
--chip-color:#8F5FEE ;
--border-color: #CDCDCD;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
html {
  scroll-behavior:smooth;
	height: 100%;
}

body {
  font-family: "Montserrat", "SF UI Text", "Helvetica Neue", "Segoe UI",
  "Oxygen", "Ubuntu", "Cantarell", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}


.page__container{
  width: auto;
  height: auto;
  display: flex;
  flex-direction:column;
  margin: 20px;  
}
.module__heading{
  font-size: 18px;
}
.custom-button {
  font-size: 13px;
  padding: 8px 15px;
  border-width: 0.;

}.p-button-label {
  font-weight: 400; /* Adjust the font weight as needed */
}
.p-tabview-title {
  font-weight: 500; 
}
.custom-button:hover {
background-color: var(--secondary-color);
}
.p-datatable .p-datatable-thead>tr>th {
  background: var(--primary-color);
  color: #fff
}

.p-datatable .p-datatable-tbody>tr>td{
  font-size: 12px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon{
  color: #fff;
}

.p-datatable .p-datatable-header{
  background: var(--primary-color);
}

.p-dropdown-label		{
font-size: 13px;
}
.p-dropdown-items	{
  font-size: 13px;

}
.colleague-item {
  padding: 8px; /* Adjust as needed */
}

.colleague-item:hover {
  padding: 10px;
  cursor: pointer;
  background-color: #f0f0f0; /* Change to your desired background color */
  transition: background-color 0.3s;
}