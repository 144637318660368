/* LoadingSkeleton.css */
.skeleton-card {
    overflow: hidden;
}

.skeleton-content {
    padding: 1rem;
}

.skeleton-text {
    height: 1rem;
    width: 70%;
    background-color: #ddd;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton-text-block {
    height: 0.75rem;
    width: 50%;
    background-color: #ddd;
    display: block;
    border-radius: 0.25rem;
}

/* Align bars to the right */
.skeleton-text,
.skeleton-text-block {
    margin-left: auto;
}

/* Animation */
@keyframes loading {
    0% {
        background-position: -200%;
    }
    100% {
        background-position: 200%;
    }
}

.skeleton-text::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(0,0,0,0.05), transparent);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}
