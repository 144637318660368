
  
  .search-container {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .search-container input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 200px;
    outline: none;
  }
  
  .search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }
  